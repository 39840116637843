@font-face {
  font-family: 'Acumin Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro'), url('acumin/Acumin-RPro.woff') format('woff');
}

@font-face {
  font-family: 'Acumin Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro Italic'),
    url('acumin/Acumin-ItPro.woff') format('woff');
}

@font-face {
  font-family: 'Acumin Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro Bold'), url('acumin/Acumin-BdPro.woff') format('woff');
}

@font-face {
  font-family: 'Acumin Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro Bold Italic'),
    url('acumin/Acumin-BdItPro.woff') format('woff');
}
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('poppins/poppins-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Poppins Light'), local('Poppins-Light'),
    url('poppins/poppins-v9-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v9-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v9-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v9-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('poppins/poppins-v9-latin-300.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('poppins/poppins-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('poppins/poppins-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v12-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('poppins/poppins-v12-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('poppins/poppins-v11-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('poppins/poppins-v11-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v11-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v11-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v11-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('poppins/poppins-v11-latin-500.svg#Poppins')
      format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('poppins/poppins-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('poppins/poppins-v11-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v11-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v11-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v11-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('poppins/poppins-v11-latin-600.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('poppins/poppins-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('poppins/poppins-v11-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v11-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v11-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v11-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('poppins/poppins-v11-latin-700.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('poppins/poppins-v11-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
    url('poppins/poppins-v11-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v11-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v11-latin-800.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v11-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('poppins/poppins-v11-latin-800.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('poppins/poppins-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Poppins Black'), local('Poppins-Black'),
    url('poppins/poppins-v11-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('poppins/poppins-v11-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('poppins/poppins-v11-latin-900.woff')
      format('woff'),
    /* Modern Browsers */ url('poppins/poppins-v11-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('poppins/poppins-v11-latin-900.svg#Poppins')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  src: url('lato/latoregular.ttf');
  src: url('lato/latoregular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('lato/latobold.ttf');
  src: url('lato/latobold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* inter-100 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('inter/inter-v3-latin_cyrillic-100.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-100.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-100.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-100.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-200 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('inter/inter-v3-latin_cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-200.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-200.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-200.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-200.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-300 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('inter/inter-v3-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-300.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-300.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-regular - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('inter/inter-v3-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('inter/inter-v3-latin_cyrillic-regular.woff') format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-500 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('inter/inter-v3-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-500.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('inter/inter-v3-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-600.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-600.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('inter/inter-v3-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-700.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-700.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-800 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('inter/inter-v3-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-800.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-800.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-900 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('inter/inter-v3-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inter/inter-v3-latin_cyrillic-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('inter/inter-v3-latin_cyrillic-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inter/inter-v3-latin_cyrillic-900.woff')
      format('woff'),
    /* Modern Browsers */ url('inter/inter-v3-latin_cyrillic-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inter/inter-v3-latin_cyrillic-900.svg#Inter') format('svg'); /* Legacy iOS */
}
